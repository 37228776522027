<template>
  <generic-overview
    :route="route"
    :dialog="dialog"
    :actions="actions"
    icon="business"
    title="Leads"
  >
    <v-menu slot="toolbar-actions" bottom left>
      <v-btn slot="activator" icon>
        <v-icon>more_vert</v-icon>
      </v-btn>
      <v-list>
        <v-list-tile @click="exportLeads()">
          <v-list-tile-avatar>
            <v-icon>far fa-search</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Export leads to csv</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <table-data-provider
      slot="content"
      ref="leadsTable"
      :url="url"
      :pagination="pagination"
      :includes="includes"
      :entity-name="entityName"
      :schema="schema"
    >
      <v-data-table
        slot-scope="props"
        :headers="headers"
        :items="props.items"
        :pagination.sync="pagination"
        :total-items="props.totalItems"
        :rows-per-page-items="[20, 50, 100]"
        disable-initial-sort
        disable-page-reset
        :loading="props.isLoading"
        class="elevation-1"
      >
        <leads-overview-row
          slot="items"
          slot-scope="props"
          :lead="props.item"
        />
      </v-data-table>
    </table-data-provider>
    <router-view slot="dialog" />
  </generic-overview>
</template>

<script type="text/babel">
import TableDataProvider from "../../../components/legacy/generic/data/TableDataProvider";
import GenericOverview from "../../../components/legacy/generic/overview/Overview";
import LeadsOverviewRow from "./LeadsOverviewRow";
import EntityOverviewMixin from "../../../components/legacy/generic/entity/EntityOverviewMixin.vue";
import { lead as schema } from "@/store/schema";

export default {
  name: "bo-man-leads-overview",
  components: {
    LeadsOverviewRow,
    TableDataProvider,
    GenericOverview
  },
  mixins: [EntityOverviewMixin],
  data: () => {
    return {
      route: "distribution.leads",
      url: `api/v1/leads`,
      actions: [],
      totalItems: 0,
      items: [],
      loading: true,
      rejectReasonId: null,
      pagination: {},
      headers: [
        { text: "ID", value: "id" },
        { text: "Firs Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Country", value: "country" },
        { text: "Language", value: "language" },
        {
          text: "Actions",
          align: "right",
          value: "name",
          sortable: false,
          width: "100px"
        }
      ],
      entityName: "lead",
      includes: ["submissions", "languages"]
    };
  },
  computed: {
    schema() {
      return schema;
    }
  },
  methods: {
    onClickShow: function(item) {
      this.$router.push({
        name: "distribution.leads.show",
        params: { id: item.id }
      });
    },
    onClickCreate: function() {
      this.$router.push({ name: "distribution.leads.create" });
    },
    exportLeads: function() {
      window.location.href = "http://be.buldit.test/api/v1/leads/export";
    }
  }
};
</script>
