<template>
  <tr>
    <td>{{ lead.id }}</td>
    <td>{{ submissionFirstName }}</td>
    <td>{{ submissionLastName }}</td>
    <td><!--{{ props.item.country.name }}--></td>
    <td>{{ languageName }}</td>
    <td>
      <v-btn
        icon
        :to="{ name: 'management.leads.show', params: { id: lead.id } }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
export default {
  name: "leads-overview-row",
  props: {
    lead: {
      type: Object,
      required: true
    }
  },
  computed: {
    submissionFirstName() {
      if (this.lead.submission !== null) {
        const submission = this.$store.getters["entities/getEntity"]({
          name: "submission",
          id: this.lead.submission
        });
        return submission.firstName;
      }
      return null;
    },
    submissionLastName() {
      if (this.lead.submission !== null) {
        const submission = this.$store.getters["entities/getEntity"]({
          name: "submission",
          id: this.lead.submission
        });
        return submission.lastName;
      }
      return null;
    },
    languageName() {
      if (this.lead.language !== null) {
        const language = this.$store.getters["entities/getEntity"]({
          name: "language",
          id: this.lead.language
        });
        return language.name;
      }
      return null;
    }
  }
};
</script>
